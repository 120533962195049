import { IResult } from "src/Shared/Interfaces";
import { CompanyApiUrls } from "../Tools/CompanyApiUrls";
import { Observable } from "rxjs";
import { IComment } from "../Interfaces/IComment";
import { ICommentFilter } from "../Interfaces/ICommentFilter";
import { BaseService } from "src/Shared/Services/Base.service";

class CommentService extends BaseService {
  public addComment(request: ICommentFilter) {
    return this.http.post<IResult<string>>(CompanyApiUrls.SaveComment, request);
  }

  public getComments(
    request: string,
    search: string,
    isSLARelated: boolean
  ): Observable<IComment[]> {
    return this.http.get<IComment[]>(
      CompanyApiUrls.GetComment + `?CompanyRowID=${request}&search=${search}&isSlaRelated=${isSLARelated}`
    );
  }
}

export default new CommentService();
