import React, { useEffect, useMemo, useState } from "react";
import ModalContent from "src/Shared/Components/ModalContent/ModalContent";
import {
  Box,
  CircularProgress,
  Container,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { getFormValidation } from "src/Shared/Components";
import { fields } from "./fields";
import { useStore } from "src/Shared/Hooks";
import { EDITOR_TYPES } from "src/Shared/Enums";
import CommentService from "../../Services/Comment.service";
import { finalize } from "rxjs";
import { IResult } from "src/Shared/Interfaces";
import { ToastService } from "src/Shared/Services";
import { AxiosError } from "axios";
import { IComment, ICommentFilter } from "../../Interfaces";
import ViewInputControl from "src/Shared/Components/ViewInputControl/ViewInputControl";
import { observer } from "mobx-react";
import CommentIcon from "@mui/icons-material/Comment";
import { useStyles } from "./AddComment.styles";
import { debug } from "util";
import useDebounce from "src/Shared/Hooks/UseDebounce.hook";

type Props = {
  companyRowID: string;
  isSlaRelated: boolean;
};

const AddComments: React.FC<Props> = ({ companyRowID, isSlaRelated }) => {
  const {
    rootStore: {
      addCommentStore: { commentList, getCommentList, gridLoading },
    },
  } = useStore();

  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");
  const commentForm = useMemo(() => getFormValidation(fields), []);
  const getField = (key: string) => commentForm.$(key);

  const classes = useStyles();

  useEffect(() => {
    setUpdateCommentList(commentList);
  }, [commentList]);

  useEffect(() => {
    getCommentList(companyRowID, search, isSlaRelated);
  }, []);

  useDebounce(
    () => {
      getCommentList(companyRowID, search, isSlaRelated);
    },
    500,
    [search]
  );

  const onSubmitForm = (): any => {
    if (!commentForm.isValid) {
      commentForm.showErrors();
      return;
    }
    const request: ICommentFilter = { ...commentForm.values(), companyRowID };
    request.isSLARelated = isSlaRelated;
    setLoading(true);
    CommentService.addComment(request)
      .pipe(
        finalize(() => {
          setLoading(false);
        })
      )
      .subscribe({
        next: (data: IResult<string>) => {
          commentForm.clear();
          getCommentList(companyRowID, search, isSlaRelated);
          ToastService.success(data.message);
        },
        error: (error: AxiosError) => ToastService.axiosError(error),
      });
  };

  const [updateCommentList, setUpdateCommentList] = useState(commentList);

  const toggleReadMore = (value: number) => {
    updateCommentList.map((x) => {
      if (x.companyCommentID == value) {
        x.isExpanded = !x.isExpanded;
      }
      return x;
    });
    setUpdateCommentList(updateCommentList);
  };

  return (
    <>
      <ModalContent
        buttonText="Add new comment"
        title={(isSlaRelated ? "SLA Related " : "") + "Comments"}
        onSaveButtonClick={() => onSubmitForm()}
        btnLoading={loading}
      >
        <Box className={classes.main}>
          <TextField
            sx={{ mb: 2 }}
            fullWidth
            value={search || ""}
            label={"Search Comments..."}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <React.Fragment>
                  {gridLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                </React.Fragment>
              ),
            }}
          />

          <Box className={classes.root}>
            {updateCommentList &&
              updateCommentList.map((value) => {
                return (
                  <Container
                    className={classes.commentsGrid}
                    fixed
                    key={value.companyCommentID}
                  >
                    <Grid container xs={12} className={classes.comments}>
                      <Grid item xs={8}>
                        <Typography variant="body2">
                          {value.createdBy} ({value.createdOn}):
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        sx={{ mt: 2 }}
                        key={value.companyCommentID}
                      >
                        <Typography variant="subtitle1">
                          {value.isExpanded
                            ? value.comment.slice(0, 230)
                            : value.comment}
                          {value.comment.length >= 230 && (
                            <span
                              className={classes.readMore}
                              onClick={() =>
                                toggleReadMore(value.companyCommentID)
                              }
                            >
                              {value.isExpanded
                                ? "...Read more"
                                : "...Show less"}
                            </span>
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Container>
                );
              })}
          </Box>
          <Box sx={{ mt: 2 }}>
            <ViewInputControl
              type={EDITOR_TYPES.TEXT_FIELD}
              rows={3}
              form={commentForm}
              field={getField("comment")}
              multiline={true}
            />
          </Box>
        </Box>
      </ModalContent>
    </>
  );
};

export default observer(AddComments);
