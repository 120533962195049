export enum EDITOR_TYPES {
  TEXT_FIELD,
  AUTO_COMPLETE,
  AUTO_COMPLETE_ASYNC,
  RADIO_BUTTON,
  CHECKBOX,
  DROPDOWN,
  SWITCH,
  TEXTAREA,
  FILE_UPLOADER,
  CUSTOM,
  Phone_Number,
  DATE = "Date",
  TIME = "Time",
  DATE_RANGE = "DATE_RANGE",
  NUMERIC_RANGE = "NUMERIC_RANGE",
}
