import { action, makeObservable, observable } from "mobx";
import { IPageRequest, IPagedResult } from "src/Shared/Interfaces";
import { IRootStore } from "src/Shared/Stores/Root.store";
import { ICompanyContact } from "../Interfaces/ICompanyContact";
import { IContactFilter } from "../Interfaces/IContactFilter";
import { Subscription, finalize, forkJoin, map } from "rxjs";
import CompanyContactService from "../Services/CompanyContact.service";
import { VendorType } from "../Enums/VendorType";

export class CompanyContactStore {
  rootStore: IRootStore;
  public gridLoading: boolean = false;
  public primaryContactID: number | null = null;
  public healthTickPrimaryContactID: number | null = null;
  public selectedCompanyVendorTypes: number[] = [];

  public companyContactList: IPagedResult<ICompanyContact> = {
    pageNumber: 1,
    pageSize: 10,
    results: [],
    totalNumberOfRecords: 0,
  };

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      gridLoading: observable,
      companyContactList: observable,
      setGridLoading: action,
      getCompanyContact: action.bound,
      setCompanyContactList: action,
      refreshCompanyContactList: action,
      primaryContactID: observable,
      healthTickPrimaryContactID: observable,
      getCompanyVendorType: action.bound,
      selectedCompanyVendorTypes: observable,
    });
    this.rootStore = rootStore;
  }

  setGridLoading = (flag: boolean): void => {
    this.gridLoading = flag;
  };

  getCompanyVendorType(companyRowID: string) {
    this.setGridLoading(true);
    CompanyContactService.getCompanyVendorType(companyRowID)
      .pipe(
        finalize(() => {
          this.setGridLoading(false);
        })
      )
      .subscribe((data: number[]) => {
        this.selectedCompanyVendorTypes = data;
      });
  }

  getCompanyContact(request: IPageRequest<IContactFilter>): Subscription {
    this.setGridLoading(true);
    return forkJoin([
      CompanyContactService.getCompanyConatctList(request),
      CompanyContactService.getPrimaryContactID(
        request.filterCollection.companyRowID
      ),
    ])
      .pipe(
        finalize(() => {
          this.setGridLoading(false);
        })
      )
      .subscribe(([listData, primaryContactID]) => {
        if (listData) {
          this.setCompanyContactList(listData);
          this.sethealtHTickPrimaryContactID(
            listData.results.find((x) =>
              x.vendorTypeID.some(
                (y) => y == VendorType.Healthtick && x.isPrimaryContact
              )
            )?.companyContactID ?? null
          );
        }
        this.setPrimaryContactID(primaryContactID);
      });
  }

  refreshCompanyContactList = (companyRowID: string) => {
    this.getCompanyContact({
      pageNumber: 1,
      pageSize: 10,
      filterCollection: {
        companyRowID: companyRowID,
      },
      sortCollection: [],
    });
  };

  setCompanyContactList(data: IPagedResult<ICompanyContact>) {
    if (data) {
      this.companyContactList = data;
    }
  }

  setPrimaryContactID = (val: number) => {
    this.primaryContactID = val ? val : null;
  };
  sethealtHTickPrimaryContactID = (val: number) => {
    this.healthTickPrimaryContactID = val ? val : null;
  };
}
