import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { CustomDataGrid } from "src/Shared/Components";
import { useStore } from "src/Shared/Hooks/UseStore.hook";
import { columns } from "./CompanyGrid.column";
import { LinearProgress } from "@mui/material";
import CompanyGridToolbar from "../CompanyGridToolbar/CompanyGridToolbar";
import { GridPaginationModel, GridRowParams } from "@mui/x-data-grid";
import { ICompanyFilter, ICompanyList } from "../../Interfaces";
import { IPageRequest, ISelectOption } from "src/Shared/Interfaces";
import { fields } from "./fields";
import { useNavigate } from "react-router-dom";
import { useUpdateEffect } from "src/Shared/Hooks";

const CompanyGrid: FC = () => {
  const {
    rootStore: {
      companyStore: {
        gridLoading,
        companyList,
        getCompanies,
        intilizeFilterForm,
        companyFilterForm,
        companyFilterCollection,
        setCompanyFilterCollection,
        setPagination,
        pagination,
      },
    },
  } = useStore();

  const [request, setRequest] = useState<IPageRequest<ICompanyFilter>>();

  useEffect(() => {
    intilizeFilterForm(fields());
    getCompanies({
      filterCollection: companyFilterCollection,
      sortCollection: [],
      pageNumber: pagination.page + 1,
      pageSize: pagination.pageSize,
    });
  }, []);

  useUpdateEffect(() => {
    onGridPaginationChange({
      page: 0,
      pageSize: 25,
    });
  }, [companyFilterCollection]);

  const applyFilter = () => {
    setCompanyFilterCollection(getFilterCollection());
  };

  const getFilterCollection = () => {
    const filterColletion = (companyFilterForm?.values() || {
      companyName: "",
      modifiedBy: { label: "", value: "" },
      franchiseID: [],
      totalStaffNumber: null,
      vendorTypeID: null,
      leadTypeIDs: [],
      services: [],
      dealStage: [],
      isSLASigned: null,
      slaExpiryDate: null,
      tddaPrimaryRelationshipManager: [],
      healthTickPrimaryRelationshipManager: [],
      createdBy: [],
      primaryContactFullName: null,
      status: null,
    }) as ICompanyFilter;
    filterColletion.totalStaffNumber = filterColletion.totalStaffNumber
      ? filterColletion.totalStaffNumber
      : null;
    filterColletion.isSLASigned = filterColletion.isSLASigned
      ? filterColletion.isSLASigned
      : null;
    filterColletion.slaExpiryDate = filterColletion.slaExpiryDate
      ? filterColletion.slaExpiryDate
      : null;
    filterColletion.companyName = parseCompanyName(filterColletion.companyName);

    return filterColletion;
  };
  const parseCompanyName = (companyName?: ISelectOption) => {
    if (companyName) {
      companyName.value = companyName.value ? companyName.value : 0;
      return companyName;
    }
    return null;
  };

  useEffect(() => {
    if (request) getCompanies(request);
  }, [request]);

  const onGridPaginationChange = (data: GridPaginationModel) => {
    setPagination({
      ...data,
    });
  };

  useUpdateEffect(() => {
    setRequest({
      filterCollection: companyFilterCollection,
      sortCollection: [],
      pageNumber: pagination.page + 1,
      pageSize: pagination.pageSize,
    });
  }, [pagination]);

  const navigate = useNavigate();

  const onOpen = (params: GridRowParams) => {
    navigate(`${params.id}/overview`);
  };

  return (
    <>
      <CustomDataGrid
        loading={gridLoading}
        columns={columns}
        rowSelection={false}
        rows={companyList.results || []}
        rowCount={companyList.totalNumberOfRecords || 0}
        disableColumnSelector={false}
        style={{
          height: "calc(100vh - 95px)",
        }}
        paginationModel={pagination}
        onPaginationModelChange={(model, _) => onGridPaginationChange(model)}
        slots={{
          toolbar: CompanyGridToolbar,
          loadingOverlay: LinearProgress,
        }}
        getRowId={(row: ICompanyList) => row.companyRowID}
        onRowDoubleClick={onOpen}
        slotProps={{
          toolbar: {
            onApplyFilter: () => applyFilter(),
          },
        }}
      />
    </>
  );
};

export default observer(CompanyGrid);
