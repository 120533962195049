import { action, makeObservable, observable } from "mobx";
import { Subscription, finalize } from "rxjs";
import { IRootStore } from "src/Shared/Stores/Root.store";
import CommentService from "../Services/Comment.service";
import { IComment } from "../Interfaces/IComment";
import { AxiosError } from "axios";
import { ToastService } from "src/Shared/Services";
import DateTimeUtils from "../../../Shared/Tools/DateTimeUtils";

export class CommentStore {
  rootStore: IRootStore;
  public gridLoading: boolean = false;

  public commentList: IComment[] = [];

  constructor(rootStore: IRootStore) {
    makeObservable(this, {
      gridLoading: observable,
      commentList: observable,
      setGridLoading: action,
      getCommentList: action.bound,
      setCommentList: action,
    });
    this.rootStore = rootStore;
  }
  setGridLoading = (flag: boolean): void => {
    this.gridLoading = flag;
  };

  getCommentList(
    request: string,
    search: string,
    isSLARelated: boolean
  ): Subscription {
    this.setGridLoading(true);
    return CommentService.getComments(request, search, isSLARelated)
      .pipe(
        finalize(() => {
          this.setGridLoading(false);
        })
      )
      .subscribe({
        next: (data) => {
          this.setCommentList(data);
        },
        error: (error: AxiosError) => ToastService.axiosError(error),
      });
  }

  setCommentList(data: IComment[]) {
    if (data) {
      data = data.map((x) => {
        x.createdOn = DateTimeUtils.dateToLocalTimeZone(x.createdOn);
        x.isExpanded = x.comment.length > 230 ? true : false;
        return x;
      });
      this.commentList = data;
    }
  }
}
