import React, { ChangeEvent, FC, Fragment, ReactNode } from "react";
import { IViewInputControl, ISelectOption } from "../../Interfaces";
import { useStyles } from "./ViewInputControl.styles";
import classNames from "classnames";
import {
  Typography,
  FormLabel,
  TextField,
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Checkbox,
  Select,
  MenuItem,
  InputLabel,
  TextareaAutosize,
  Switch,
  Autocomplete,
} from "@mui/material";
import { DATE_FORMAT, EDITOR_TYPES } from "../../Enums";
import { observer } from "mobx-react";
import { action } from "mobx";
import moment from "moment";
import MobxReactForm from "mobx-react-form";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/de";
import AsyncAutocomplete from "../CoreUI/AsyncAutocomplete/AsyncAutocomplete";
import { NumericFormatCustom } from "../CoreUI/NumericFormatCustom/NumericFormatCustom";
import FileUploader from "../CoreUI/FileUploader/FileUploader";
import DateRangePicker from "../CoreUI/DateRangePicker/DateRangePicker";
import NumericRangeInput from "../CoreUI/NumericRangeInput/NumericRangeInput";
import MuiPhoneNumber from "mui-phone-number";
interface Props extends IViewInputControl {
  form?: MobxReactForm;
  handleClose?: () => void;
  value?: any;
  onKeyPress?: (e) => void;
  onFieldChange?: (e) => void;
  onButtonClickHandler?: (e) => void;
}

const ViewInputControl: FC<Props> = (props: Props) => {
  const { isEditingMode, classes, form, onValueChange, options, hidden } =
    props;
  const styleClasses = useStyles();
  const inputClass = classNames({
    [styleClasses.flexRow]: true,
    [classes?.flexBlock]: classes?.flexBlock,
    [styleClasses.textArea]: true,
  });

  const getField = (key: string) => form.$(key);
  const fieldValue = (): any => {
    const { type, fieldKey } = props;
    const field = props.field || props.form.$(fieldKey);
    switch (type) {
      case EDITOR_TYPES.TEXT_FIELD:
        return field.value || "-";
      case EDITOR_TYPES.DROPDOWN: {
        if (props.multiple && field.value instanceof Array) {
          return field.value || [];
        } else if (props.multiple) {
          return [];
        }
        return field.value || " ";
      }
      case EDITOR_TYPES.AUTO_COMPLETE: {
        if (field.value && !props.multiple) {
          return options.find((x) => field.value === x.value);
        }
        if (props.multiple && field.value instanceof Array) {
          return options.filter((x) => field.value.includes(x.value));
        } else if (props.multiple) {
          return [];
        }

        return null;
      }
      case EDITOR_TYPES.FILE_UPLOADER: {
        if (props.multiple && field.value instanceof Array) {
          return field.value || [];
        } else if (props.multiple) {
          return [];
        }
        return field.value || {};
      }
      default:
        return "-";
    }
  };

  const onValChange = (value: any, fieldKey: string): void => {
    const { type, multiple } = props;
    const field = props.field || props.form.$(fieldKey);
    field.showErrors(true);
    switch (type) {
      case EDITOR_TYPES.AUTO_COMPLETE: {
        let val;
        if (value && multiple) {
          val = value.map((c) => c.value);
        }
        if (value && !multiple) {
          val = value.value || null;
        }
        getField(fieldKey).set(val);
        break;
      }
      default:
        getField(fieldKey).set(value);
    }
    onValueChange(value, fieldKey);
  };

  // const switchHandler = (value: string) => {
  //   onValueChange(value, props.field.name);
  // };

  const readOnlyFieldValue = (): ReactNode => {
    const { classes } = props;
    return (
      <>
        <Typography align="left">{fieldValue()}</Typography>
      </>
    );
  };

  // const changeDatePickerValue = (value, date, field, dateTimeFormat) => {
  //   if (date && !moment(date).isValid()) {
  //     field.invalidate(`Invalid date or time (${dateTimeFormat})`);
  //   }
  //   onValueChange(date ? date : "", field.key);
  // };

  const editableContent = (): ReactNode => {
    const {
      classes,
      type,
      isDisabled,
      dateTimeFormat,
      showLabel,
      disabled,
      options,
      multiple,
      isCustom,
      isadornment,
      endAdornment,
      startAdornment,
      minDate,
      maxDate,
      minTime,
      maxTime,
      form,
      handleClose,
      minRows,
      className,
      inputProps,
      onKeyDown,
      onKeyPress,
      onFieldChange,
      onButtonClickHandler,
      dateFormat,
      timeFormat,
      width,
      fieldKey,
      attachmentType,
      apiObservable,
      isMasked,
      disabledOptions,
      customComponent,
      helperText,
      disabledEnd,
      disabledStart,
    } = props;
    const field = props.field || form.$(fieldKey);
    const { hasError, touched, value, placeholder, key, errorSync } = field;

    // field.disabled
    switch (type) {
      case EDITOR_TYPES.DATE:
        return (
          <>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="en-gb"
            >
              <DatePicker
                sx={{
                  width: width,
                }}
                {...field.bind()}
                label={field.label}
                format={dateFormat}
                value={field.value ? moment(field.value, dateFormat) : null}
                minDate={minDate}
                maxDate={maxDate}
                defaultValue={null}
                disabled={isDisabled || field.disabled}
                onChange={(date) => {
                  // changeDatePickerValue(value, date, field, dateTimeFormat);
                  onValChange(date, key);
                }}
                readOnly={inputProps?.readOnly}
                slotProps={{
                  textField: {
                    error: !!field.error,
                  },
                }}
              />
            </LocalizationProvider>
          </>
        );

      case EDITOR_TYPES.TIME:
        return (
          <>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="en-gb"
            >
              <TimePicker
                sx={{
                  width: width,
                }}
                {...field.bind()}
                label={field.label}
                format={timeFormat}
                onChange={(date) => {
                  onValChange(date, key);
                }}
                minTime={minTime}
                maxTime={maxTime}
                disabled={isDisabled}
                value={field.value || null}
              />
            </LocalizationProvider>
          </>
        );

      case EDITOR_TYPES.RADIO_BUTTON:
        return (
          <>
            <FormControl error={!!field.error}>
              <FormLabel>{field.label}</FormLabel>
              <RadioGroup
                row
                defaultValue={field.value || null}
                value={field.value}
                onChange={({ target }) => {
                  onValChange(target.value, key);
                }}
              >
                {options.map((option, index) => (
                  <Fragment key={index}>
                    <FormControlLabel
                      value={option.value}
                      disabled={isDisabled}
                      control={<Radio />}
                      label={option.label}
                      key={index}
                    />
                  </Fragment>
                ))}
              </RadioGroup>
            </FormControl>
          </>
        );

      case EDITOR_TYPES.TEXTAREA:
        return (
          <TextareaAutosize
            minRows={minRows}
            placeholder={placeholder}
            onChange={({ target }) => {
              onValChange(target.value, field.key);
            }}
            value={props.value || value || ""}
            className={styleClasses.textArea}
          />
        );

      case EDITOR_TYPES.AUTO_COMPLETE:
        return (
          <>
            <Autocomplete
              value={fieldValue() || null}
              fullWidth
              multiple={multiple}
              onChange={(_, newValue) => {
                onValChange(newValue, key);
              }}
              options={options}
              sx={{ width: { width } }}
              getOptionLabel={(option: ISelectOption) => option.label || ""}
              getOptionKey={(item: ISelectOption) => item.value}
              isOptionEqualToValue={(option, value) =>
                option.value === value?.value
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={field.label}
                  error={!!field.error}
                />
              )}
              readOnly={inputProps?.readOnly}
              disabled={isDisabled}
              onFocus={() => {
                field.onFocus();
                props.onFocus(key);
              }}
              onBlur={() => {
                field.onBlur();
                props.onBlur(key, value);
              }}
            />
          </>
        );

      case EDITOR_TYPES.AUTO_COMPLETE_ASYNC:
        return (
          <>
            <AsyncAutocomplete
              multiple={multiple}
              label={field.label}
              value={field.value}
              sx={{ width: { width } }}
              readOnly={inputProps?.readOnly}
              onValueChange={(newValue) => {
                onValChange(newValue, key);
              }}
              disabled={isDisabled}
              apiObservable={apiObservable}
              error={!!field.error}
            />
          </>
        );

      case EDITOR_TYPES.CHECKBOX:
        return (
          <FormControlLabel
            disabled={disabled}
            control={
              <Checkbox
                checked={field.value}
                name={field.label}
                onChange={({ target }) => {
                  onValChange(target.checked, field.key);
                }}
              />
            }
            label={field.label}
          />
        );

      case EDITOR_TYPES.DROPDOWN:
        return (
          <>
            <FormControl fullWidth>
              <InputLabel>{showLabel && field.label}</InputLabel>
              <Select
                sx={{
                  width: width,
                }}
                multiple={multiple}
                className={styleClasses.selectBox}
                fullWidth
                value={fieldValue() || []}
                {...field.bind()}
                label={field.label}
                error={!!field.error}
                onChange={({ target }) => {
                  onValChange(target.value, field.key);
                }}
                MenuProps={{ PaperProps: { sx: { maxHeight: 500 } } }}
                inputProps={inputProps && inputProps}
              >
                {options &&
                  options.length > 0 &&
                  options.map((option, index) => (
                    <MenuItem
                      disabled={
                        disabledOptions &&
                        disabledOptions.some((x) => x.value === option.value)
                      }
                      value={option.value}
                      key={index}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                {options?.length === 0 && <MenuItem value="">Select</MenuItem>}
              </Select>
            </FormControl>
          </>
        );

      case EDITOR_TYPES.SWITCH:
        return (
          <>
            <FormControl className={styleClasses.switch} fullWidth>
              <FormControlLabel
                disabled={field.disabled}
                // {...field.bind()}
                // value={field.value}
                // className={styleClasses.swichBox}
                control={
                  <Switch
                    checked={Boolean(field.value || false)}
                    onChange={(_, value) => onValChange(value, key)}
                  />
                }
                label={field.label}
                labelPlacement="top"
              />
            </FormControl>
          </>
        );

      case EDITOR_TYPES.FILE_UPLOADER:
        return (
          <>
            <FileUploader
              label={showLabel && field.label}
              onFileChange={(value) => {
                onValChange(value, key);
              }}
              value={fieldValue()}
              multiple={multiple}
              attachmentType={attachmentType ?? null}
              disabled={field.disabled || isDisabled}
            />
          </>
        );

      case EDITOR_TYPES.DATE_RANGE:
        return (
          <>
            <DateRangePicker
              label={field.label}
              minDate={minDate}
              maxDate={maxDate}
              onChange={(dataRange) => {
                onValChange(dataRange, fieldKey);
              }}
              disabledEnd={disabledEnd}
              disabledStart={disabledStart}
              readonly={inputProps?.readOnly}
              value={field.value}
            />
          </>
        );
      case EDITOR_TYPES.NUMERIC_RANGE:
        return (
          <>
            <NumericRangeInput
              label={field.label}
              onChange={(range) => {
                onValChange(range, fieldKey);
              }}
              value={field.value}
              disabled={disabled}
            />
          </>
        );

      case EDITOR_TYPES.CUSTOM:
        return customComponent;

      case EDITOR_TYPES.Phone_Number:
        return (
          <>
            <MuiPhoneNumber
              countryCodeEditable={false}
              label={field.label}
              defaultCountry={"nz"}
              onlyCountries={inputProps?.ristrictCountry}
              onChange={(value) => {
                onValChange(value, fieldKey);
              }}
              variant="outlined"
              fullWidth
              value={field.value}
              error={!!field.error}
            />
          </>
        );
      case EDITOR_TYPES.TEXT_FIELD:
      default:
        const _hasError = Boolean(Boolean(form?.submitted) && !!field.error);

        return (
          <>
            <TextField
              fullWidth
              autoComplete="off"
              {...field.bind()}
              label={showLabel && field.label}
              onChange={action(({ target }: ChangeEvent<HTMLInputElement>) => {
                onValChange(target.value, key);
              })}
              onBlur={() => {
                field.onBlur();
                props.onBlur(key, value);
              }}
              onFocus={() => {
                field.onFocus();
                props.onFocus(key);
              }}
              type={field.type || "text"}
              variant="outlined"
              value={props.value || value || ""}
              placeholder={placeholder || field.label}
              error={!!field.error}
              // helperText={_hasError ? errorSync : ""}
              helperText={helperText}
              disabled={isDisabled || field.disabled}
              multiline={props.multiline}
              rows={props.rows}
              className={classNames({
                [styleClasses.input]: true,
                [classes?.inputControl]: classes?.inputControl,
              })}
              InputProps={{
                endAdornment: isadornment ? endAdornment : <></>,
                startAdornment: isadornment ? startAdornment : <></>,
                inputComponent: isMasked && (NumericFormatCustom as any),
                inputProps: inputProps && inputProps,
              }}
              onKeyPress={(e) => onKeyPress(e)}
            />
          </>
        );
    }
  };

  const readOnlyContent = (): ReactNode => {
    const { classes, field, showLabel = true } = props;

    return (
      <>
        {showLabel && <FormLabel>{field.label}</FormLabel>}

        {readOnlyFieldValue()}
      </>
    );
  };
  return (
    <>
      {!hidden ? (
        <div className={inputClass}>
          {isEditingMode ? editableContent() : readOnlyContent()}
        </div>
      ) : null}
    </>
  );
};

ViewInputControl.defaultProps = {
  hidden: false,
  isEditingMode: true,
  showLabel: true,
  checked: false,
  dateFormat: DATE_FORMAT.DATE_FORMAT,
  multiple: false,
  onFocus: (fieldKey: string) => null,
  onBlur: (fieldKey: string, value: any) => null,
  onCustomLabelClick: () => null,
  onKeyPress: (e) => null,
  width: "100%",
  onValueChange(value, fieldKey) {},
};

export default observer(ViewInputControl);
