import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  main: {
    height: "calc(100vh - 250px)",
  },
  root: {
    font: "inherit",
    height: "calc(100% - 180px)",
    minHeight: "300px",
    overflowY: "auto",
    position: "relative",
  },
  commentsGrid: {
    height: "auto",
    backgroundColor: "#F2F3F4",
    padding: "15px",
    marginBottom: "5px",
    borderRadius: "10px",
  },
  readMore: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  comments: {
    display: "flex",
    wordWrap: "break-word",
    alignItems: "center",
  },
}));
