import { GridColDef } from "@mui/x-data-grid";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import { IconButton } from "@mui/material";
import { VendorType } from "../../Enums/VendorType";
import { ISelectOption } from "src/Shared/Interfaces";

export const contactColumns = (
  updateContact,
  vendorTypes: ISelectOption[]
): GridColDef[] => {
  return [
    {
      field: "fullName",
      headerName: "Full Name",
      editable: false,
      width: 250,
      sortable: false,
    },
    {
      field: "jobTitle",
      headerName: "Job Title",
      editable: false,
      width: 150,
      sortable: false,
    },
    {
      field: "emailAddress",
      headerName: "Email",
      editable: false,
      width: 250,
      sortable: false,
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      editable: false,
      width: 250,
      sortable: false,
    },
    {
      field: "officeNumber",
      headerName: "Office Number",
      editable: false,
      width: 250,
      sortable: false,
      valueFormatter(params) {
        return params.value && params.value.length > 0 ? params.value : "-";
      },
    },
    {
      field: "staffPrimaryOfficeAddress",
      headerName: "Address",
      editable: false,
      width: 350,
      sortable: false,
    },
    {
      field: "vendorTypeID",
      headerName: "Contact Type",
      editable: false,
      width: 150,
      sortable: false,
      valueFormatter(params) {
        return  vendorTypes.filter(x=> params.value.includes(x.value)).map(x=> x.label).join(", ");
      },
    },
    {
      field: "isPrimaryContact",
      headerName: "Contact Preferences",
      editable: false,
      width: 140,
      sortable: false,
      valueFormatter(params) {
        return params.value ? "Primary" : "Secondary";
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      editable: false,
      width: 70,
      sortable: false,
      renderCell: (params) => (
        <IconButton color="primary" onClick={() => updateContact(params.row)}>
          <EditTwoToneIcon />
        </IconButton>
      ),
    },
  ];
};
