const baseCompanyContactApiUrls = "/api/Company";

export const CompanyContactApiUrl = {
  GetContacts: `${baseCompanyContactApiUrls}/GetContacts`,
  Contacts: `${baseCompanyContactApiUrls}/Contacts`,
  UpdateContacts: `${baseCompanyContactApiUrls}/UpdateContacts`,
  GetPrimaryContactID: (companyRowID: string) =>
    `${baseCompanyContactApiUrls}/GetPrimaryContactID?companyRowID=${companyRowID}`,
  GetCompanyVendorType: (companyRowID: string) =>
    `${baseCompanyContactApiUrls}/GetCompanyVendorType?companyRowID=${companyRowID}`,
};
