import React, { useEffect, useMemo, useState } from "react";
import { IGridFormControlProps } from "src/Shared/Interfaces/IGridFormControlProps.interface";
import { EDITOR_TYPES } from "src/Shared/Enums";
import { observer } from "mobx-react";
import GridFormControl from "src/Shared/Components/GridFormControl/GridFormControl";
import { useModal, useStore } from "src/Shared/Hooks";
import ModalContent from "src/Shared/Components/ModalContent/ModalContent";

type Props = {
  onApplyFilter: () => void;
};

const CompanyGridFilterPanel: React.FC<Props> = ({ onApplyFilter }) => {
  const {
    rootStore: {
      commonStore: {
        loadCommonData,
        supportiveRegions,
        vendorTypes,
        extendVendorTypes,
        leadTypeList,
        serviceTypeList,
        dealStatusList,
        clientStatus,
      },
      companyStore: { companyFilterForm, companyFilterCollection },
    },
  } = useStore();

  useEffect(() => {
    loadCommonData();
    companyFilterForm.set(companyFilterCollection);
  }, []);

  const getField = (key: string) => companyFilterForm.$(key);

  const modal = useModal();

  const gridProps: IGridFormControlProps = {
    row: 2,
    col: 9,
    spacing: 2,
    itemResponsive: {
      lg: 6,
      md: 6,
      sm: 6,
    },
    inputControls: [
      {
        field: getField("franchiseID"),
        fieldKey: "franchiseID",
        type: EDITOR_TYPES.AUTO_COMPLETE,
        options: supportiveRegions,
        multiple: true,
      },
      {
        field: getField("totalStaffNumber"),
        fieldKey: "companyName",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("vendorTypeID"),
        fieldKey: "vendorTypeID",
        type: EDITOR_TYPES.DROPDOWN,
        options: extendVendorTypes,
        multiple: false,
      },
      {
        field: getField("leadTypeIDs"),
        fieldKey: "leadTypeIDs",
        type: EDITOR_TYPES.AUTO_COMPLETE,
        options: leadTypeList,
        multiple: true,
      },
      {
        field: getField("tddaPrimaryRelationshipManager"),
        fieldKey: "tddaPrimaryRelationshipManager",
        multiple: true,
        type: EDITOR_TYPES.AUTO_COMPLETE_ASYNC,
      },
      {
        field: getField("healthTickPrimaryRelationshipManager"),
        fieldKey: "healthTickPrimaryRelationshipManager",
        multiple: true,
        type: EDITOR_TYPES.AUTO_COMPLETE_ASYNC,
      },
      {
        field: getField("services"),
        fieldKey: "services",
        multiple: true,
        options: serviceTypeList,
        type: EDITOR_TYPES.AUTO_COMPLETE,
      },
      {
        field: getField("dealStage"),
        fieldKey: "dealStage",
        multiple: true,
        options: dealStatusList,
        type: EDITOR_TYPES.AUTO_COMPLETE,
      },
      {
        field: getField("isSLASigned"),
        fieldKey: "isSLASigned",
        type: EDITOR_TYPES.RADIO_BUTTON,
        options: [
          { label: "Yes", value: true },
          { label: "No", value: false },
          { label: "Clear", value: null },
        ],
      },
      {
        field: getField("slaExpiryDate"),
        fieldKey: "slaExpiryDate",
        type: EDITOR_TYPES.DATE_RANGE,
        inputProps: {},
      },
      {
        field: getField("piplineValue"),
        fieldKey: "piplineValue",
        type: EDITOR_TYPES.NUMERIC_RANGE,
      },
      {
        field: getField("inProgressActions"),
        fieldKey: "inProgressActions",
        type: EDITOR_TYPES.RADIO_BUTTON,
        options: [
          { label: "Yes", value: true },
          { label: "No", value: false },
          { label: "Clear", value: null },
        ],
      },
      {
        field: getField("overdueActions"),
        fieldKey: "overdueActions",
        type: EDITOR_TYPES.RADIO_BUTTON,
        options: [
          { label: "Yes", value: true },
          { label: "No", value: false },
          { label: "Clear", value: null },
        ],
      },
      {
        field: getField("createdBy"),
        fieldKey: "companyName",
        multiple: true,
        type: EDITOR_TYPES.AUTO_COMPLETE_ASYNC,
      },
      {
        field: getField("modifiedBy"),
        fieldKey: "modifiedBy",
        type: EDITOR_TYPES.AUTO_COMPLETE_ASYNC,
      },
      {
        field: getField("primaryContactFullName"),
        fieldKey: "primaryContactFullName",
        type: EDITOR_TYPES.TEXT_FIELD,
      },
      {
        field: getField("status"),
        fieldKey: "status",
        type: EDITOR_TYPES.DROPDOWN,
        options: clientStatus,
        multiple: false,
      },
    ],
    form: companyFilterForm,
  };

  const onSubmitForm = (): any => {
    onApplyFilter();
    modal.close();
  };
  const onReset = () => {
    companyFilterForm.reset();
  };
  return (
    <>
      <ModalContent
        buttonText="Apply Filters"
        title="Filters"
        onSaveButtonClick={() => onSubmitForm()}
        cancelButton
        onCancel={onReset}
        cancelButtonText="Reset filters"
      >
        <GridFormControl {...gridProps} />
      </ModalContent>
    </>
  );
};

export default observer(CompanyGridFilterPanel);
