import { Field } from "mobx-react-form";
import { VendorType } from "../../Enums/VendorType";

export const fields = {
  companyRowID: {
    label: "companyRowID",
    type: "text",
  },
  companyName: {
    label: "Company Name",
    type: "text",
    rules: "required|string",
  },
  clientStatusID: {
    label: "Status",
    rules: "required",
  },
  franchiseID: {
    label: "TDDA Supporting Region",
    rules: "required",
  },
  companyType: {
    label: "National/Regional",
    rules: "required",
  },
  vendorTypeIDs: {
    label: "TDDA/Healthtick",
    rules: "required",
    value: [],
    observers: [
      {
        key: "value",
        call: ({ form, field }) => {
          let healthTickPrimaryRelationshipManager = form.$(
            "healthTickPrimaryRelationshipManager"
          );
          let isHealthTickClient = field.value.some(
            (x) => x == VendorType.Healthtick
          );
          if (isHealthTickClient) {
            healthTickPrimaryRelationshipManager.set("rules", "required");
          } else {
            healthTickPrimaryRelationshipManager.set("rules", "");
          }
        },
      },
    ],
  },
  industryID: {
    label: "Industry",
    rules: "required",
  },
  website: {
    label: "Website Url",
    rules: "string|url",
    placeholder: "https://",
  },
  totalStaffNumber: {
    label: "Total Employee Numbers",
    rules: "numeric",
    placeholder: "100",
    type: "number",
  },
  leadTypeID: {
    label: "Lead Type",
    rules: "required",
  },
  serviceTypeIDs: {
    label: "Services",
    rules: "required",
    value: [],
  },
  specialPricing: {
    label: "Special Pricing",
    value: false,
  },

  isCurrentSLAAvaliable: {
    label: "Does client have a current SLA?",
    value: null,
    observers: [
      {
        key: "value",
        call: ({ form, field }) => {
          let isHistoricSLAAvaliable = form.$("isHistoricSLAAvaliable");
          if (field.value === true || field.value === "true") {
            isHistoricSLAAvaliable.clear();
          }
          let SLAExpiredDate = form.$("slaExpiryDate");
          SLAExpiredDate.clear();
          SLAExpiredDate.set(
            "disabled",
            !(field.value === true || field.value === "true")
          );
        },
      },
    ],
  },
  isHistoricSLAAvaliable: {
    label: "Does client have a historic SLA?",
    value: null,
    observers: [
      {
        key: "value",
        call: ({ form, field }) => {
          if (field.value) {
            let SLAExpiredDate = form.$("slaExpiryDate");
            SLAExpiredDate.set("disabled", !(field.value === "Yes"));
            SLAExpiredDate.clear();
          }
        },
      },
    ],
  },
  slaExpiryDate: {
    label: "SLA Expiry Date",
    disabled: true,
    rules: "required",
    value: null,
    defaultValue: null,
  },
  primaryCompanyRelationshipManager: {
    label: "TDDA Primary Relationship Manager",
    rules: "required",
    value: null,
  },
  healthTickPrimaryRelationshipManager: {
    label: "Health Tick Primary Relationship Manager",
    value: null,
  },
  additionalCompanyRelationshipManagers: {
    label: "TDDA Additional Relationship Manager(s)",
    value: [],
  },
  salesTotalValue: {
    label: "Client Deal Value",
  },
  dealStage: {
    label: "Deal Stage",
  },
  createdByOn: {
    label: "Created By/Created On",
  },
  modifiedByOn: {
    label: "Modified By/Modified On",
  },
  importedByOn: {
    label: "Imported By/Imported On",
  },
  tddaAttachments: {
    label: "TDDA Attachment",
    value: [],
  },
  healthTickAttachments: {
    label: "HealthTick Attachment",
    value: [],
  },
  reasonForTransfer: {
    label: "Reason for Transfer",
  },
  slaComment: {
    label: "Comment",
    value: "",
  },
};
