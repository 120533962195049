import { Box, Button, Chip, InputLabel } from "@mui/material";
import React, { useState } from "react";
import { VisuallyHiddenInput } from "..";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useStyles } from "./FileUploader.styles";
import BlobService from "src/Shared/Services/Blob.service";
import CircularProgress from "@mui/material/CircularProgress";
import { AxiosError } from "axios";
import { ToastService } from "src/Shared/Services";
import { finalize } from "rxjs";
import { IAttachment } from "src/Shared/Interfaces/IAttachment";
import { useModal } from "src/Shared/Hooks";
import CustomPDFViewer from "../../CustomPDFViewer/CustomPDFViewer";
import { IBlobFileRequest } from "src/Shared/Interfaces/IBlobFileRequest";

type Props = {
  label?: string;
  value?: any;
  onFileChange?: (value: any) => void;
  multiple?: boolean;
  disabled?: boolean;
  accept?: string;
  readonly?: boolean;
  showLabel?: boolean;
  attachmentType?: number;
};

const FileUploader: React.FC<Props> = ({
  label,
  value,
  onFileChange,
  multiple,
  disabled,
  accept,
  readonly,
  showLabel,
  attachmentType,
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(false);
  const modal = useModal();
  const onUpload = (blobFiles: FileList) => {
    if (blobFiles.length <= 0) return;
    if (accept) {
      var acceptTypes = accept.split(",");
      for (let index = 0; index < blobFiles.length; index++) {
        const element = blobFiles[index];
        if (acceptTypes.some((x) => x != element.type)) {
          ToastService.error("File format not supported");
          return;
        }
      }
    }
    let blobFilesRequest: IBlobFileRequest = {
      files: blobFiles,
      attachmentTypeID: attachmentType,
    };

    setLoading(true);
    BlobService.uploadFiles(blobFilesRequest)
      .pipe(
        finalize(() => {
          setLoading(false);
        })
      )
      .subscribe({
        next: (values) => {
          if (multiple) onFileChange([...value, ...values]);
          else onFileChange(values[0]);
        },
        error: (err: AxiosError) => ToastService.axiosError(err),
      });
  };

  const onDelete = (attachmentID?: number) => {
    if (multiple)
      onFileChange([...value.filter((x) => x.attachmentID !== attachmentID)]);
    else onFileChange(null);
  };

  const onView = (attachment: IAttachment) => {
    modal.open(<CustomPDFViewer url={attachment.attachmentUrl} />);
  };
  return (
    <>
      {showLabel && (
        <InputLabel sx={{ mb: 1 }}> {label || "Attachments"}</InputLabel>
      )}

      {readonly ? (
        <>
          <Box className={classes.chipsBox}>
            {value &&
              multiple &&
              value?.map((file) => {
                return (
                  <Box className={classes.chip} key={file.attachmentID}>
                    <Chip
                      disabled={disabled}
                      label={file.name}
                      onClick={(e) => onView(file)}
                    />
                  </Box>
                );
              })}
            {value && !multiple && (
              <Box className={classes.chip}>
                <Chip
                  label={value.name}
                  disabled={disabled}
                  onClick={(e) => onView(value)}
                />
              </Box>
            )}
          </Box>
        </>
      ) : (
        <>
          <Button
            component="label"
            variant="contained"
            startIcon={
              loading ? <CircularProgress size={20} /> : <CloudUploadIcon />
            }
            disabled={loading || disabled}
          >
            Upload file
            <VisuallyHiddenInput
              type="file"
              multiple={multiple}
              onChange={(e) => onUpload(e.target.files)}
              accept={accept}
            />
          </Button>
          <Box className={classes.chipsBox}>
            {value &&
              multiple &&
              value?.map((file) => {
                return (
                  <Box className={classes.chip} key={file.attachmentID}>
                    <Chip
                      disabled={disabled}
                      label={file.name}
                      onDelete={(e) => onDelete(file.attachmentID)}
                      onClick={(e) => onView(file)}
                    />
                  </Box>
                );
              })}
            {value && !multiple && (
              <Box className={classes.chip}>
                <Chip
                  label={value.name}
                  disabled={disabled}
                  onDelete={(e) => onDelete()}
                  onClick={(e) => onView(value)}
                />
              </Box>
            )}
          </Box>
        </>
      )}
    </>
  );
};
FileUploader.defaultProps = {
  multiple: false,
  disabled: false,
  accept: "application/pdf",
  showLabel: true,
  readonly: false,
};
export default FileUploader;
