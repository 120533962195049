import { BaseService } from "./Base.service";
import { IBlobFile } from "../Interfaces/IBlobFile";
import { IAttachment } from "../Interfaces/IAttachment";
import { IBlobFileRequest } from "../Interfaces/IBlobFileRequest";

class BlobService extends BaseService {
  uploadFiles(request: IBlobFileRequest) {
    const formData = new FormData();
    for (let index = 0; index < request.files.length; index++) {
      const element = request.files[index];
      formData.append("files", element, element.name);
    }
    formData.append("attachmentTypeID", request.attachmentTypeID.toString());
    return this.http.post<IAttachment[]>("/api/blob/uploadfiles", formData);
  }
}
export default new BlobService();
