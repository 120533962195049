import { Field } from "mobx-react-form";
import { VendorType } from "../../Enums/VendorType";

export const fields = [
  "contacts",
  "contacts[].companyRowID",
  "contacts[].fullName",
  "contacts[].emailAddress",
  "contacts[].jobTitle",
  "contacts[].phoneNumber",
  "contacts[].officeNumber",
  "contacts[].isPrimaryContact",
  "contacts[].vendorTypeID",
  "contacts[].staffPrimaryOfficeAddress",
];

export const labels = {
  contacts: "Contacts ",
  "contacts[].fullName": "Full Name",
  "contacts[].emailAddress": "Email",
  "contacts[].jobTitle": "Job Title",
  "contacts[].phoneNumber": "Phone Number",
  "contacts[].officeNumber": "Office Number",
  "contacts[].isPrimaryContact": "Primary Contact",
  "contacts[].vendorTypeID": "TDDA/Health Tick",
  "contacts[].staffPrimaryOfficeAddress": "Address",
};

export const values = {
  contacts: [
    {
      companyRowID: "",
      fullName: "",
      emailAddress: "",
      jobTitle: "",
      phoneNumber: "",
      officeNumber: "",
      isPrimaryContact: true,
      vendorTypeID: [],
      staffPrimaryOfficeAddress: "",
    },
  ],
};

export const rules = {
  "contacts[].fullName": "string|required",
  "contacts[].emailAddress": "string|required|email",
  "contacts[].jobTitle": "string|required",
  "contacts[].phoneNumber": "string",
  "contacts[].officeNumber": "string",
  "contacts[].isPrimaryContact": "boolean|required",
  "contacts[].vendorTypeID": "required",
  "contacts[].staffPrimaryOfficeAddress": "string|required",
};

export const hooks = {
  contacts: {
    onAdd(instance: Field) {
      instance.state.form.$("contacts").map((v, i) => {
        if (v.$("vendorTypeID").value == "") {
          v.$("vendorTypeID").set("value", []);
        }
        v.$("isPrimaryContact").observe({
          key: "value",
          call: ({ form, field }) => {
            const currentVendorTypeIDs = v.$("vendorTypeID").value as [];

            if (field.value) {
              form.$("contacts").map((contact, index) => {
                let vendorTypes = contact.$("vendorTypeID").value as [];
                if (
                  index !== i &&
                  vendorTypes.some((x) => currentVendorTypeIDs.includes(x))
                ) {
                  contact.$("isPrimaryContact").set("disabled", true);
                  contact.$("isPrimaryContact").set("value", false);
                }
              });
            } else {
              form.$("contacts").map((contact) => {
                let vendorTypes = contact.$("vendorTypeID").value as [];
                if (vendorTypes.some((x) => currentVendorTypeIDs.includes(x))) {
                  contact.$("isPrimaryContact").set("disabled", false);
                }
              });
            }
          },
        });
      });
    },
  },
};

export default { fields, labels, values };
