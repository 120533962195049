import { Box } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import ModalContent from "src/Shared/Components/ModalContent/ModalContent";
import ViewInputControl from "src/Shared/Components/ViewInputControl/ViewInputControl";
import { EDITOR_TYPES } from "src/Shared/Enums";
import { useModal } from "src/Shared/Hooks";

type Props = {
  commentForm: any;
};

const SlaComment = ({ commentForm }: Props) => {
  const { close } = useModal();
  const getField = (key: string) => commentForm.$(key);
  const onSubmitForm = () => {
    close();
  };
  return (
    <>
      <ModalContent
        buttonText="Add new comment"
        title="Comment"
        onSaveButtonClick={() => onSubmitForm()}
      >
        <Box>
          <Box sx={{ mt: 2 }}>
            <ViewInputControl
              type={EDITOR_TYPES.TEXT_FIELD}
              rows={3}
              form={commentForm}
              field={getField("slaComment")}
              multiline={true}
            />
          </Box>
        </Box>
      </ModalContent>
    </>
  );
};

export default observer(SlaComment);
