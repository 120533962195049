import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { Breakpoint, styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useModal } from "src/Shared/Hooks";
import CircularProgress from "@mui/material/CircularProgress";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
  
}));

type Props = {
  children?: React.ReactNode;
  buttonText?: string;
  buttonDisabled?: boolean;
  title?: string;
  maxWidth?: Breakpoint;
  fullWidth?: boolean;
  cancelButton?: boolean;
  onSaveButtonClick?: () => void;
  onCancel?: () => void;
  btnLoading?: boolean;
  cancelButtonText?: string;
  cancelButtonProps?: any;
};
const ModalContent: React.FC<Props> = ({
  children,
  buttonText,
  buttonDisabled,
  onSaveButtonClick,
  title,
  maxWidth,
  fullWidth,
  cancelButton,
  btnLoading,
  onCancel,
  cancelButtonText,
  cancelButtonProps,
}) => {
  const { close } = useModal();

  return (
    <React.Fragment>
      <BootstrapDialog
        open
        fullWidth={fullWidth}
        aria-labelledby="customized-dialog-title"
        maxWidth={maxWidth}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => close()}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>{children}</DialogContent>
        <DialogActions>
          {cancelButton && (
            <Button onClick={onCancel} {...cancelButtonProps}>
              {cancelButtonText}
            </Button>
          )}
          <Button
            autoFocus
            variant="contained"
            onClick={() => onSaveButtonClick()}
            startIcon={btnLoading && <CircularProgress size={20} />}
            disabled={btnLoading || buttonDisabled}
          >
            {buttonText}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
};

ModalContent.defaultProps = {
  buttonText: "Save Changes",
  maxWidth: "md",
  fullWidth: true,
  cancelButtonText: "Cancel",
};
export default ModalContent;
