import { Grid } from "@mui/material";
import React, { Fragment } from "react";
import { IGridFormControlProps } from "src/Shared/Interfaces/IGridFormControlProps.interface";
import ViewInputControl from "../ViewInputControl/ViewInputControl";

const GridFormControl: React.FC<IGridFormControlProps> = (props) => {
  const { inputControls, row, col, spacing, form, itemResponsive } = props;

  const genrateGrid = (rows, cols) => {
    return Array.from({ length: rows }, (_, rowIndex) =>
      Array.from(
        { length: cols },
        (_, colIndex) =>
          inputControls.filter((x) => !x.hidden)[rowIndex * cols + colIndex] ||
          null
      )
    );
  };

  return (
    <div>
      {genrateGrid(col, row).map((value, index) => (
        <Grid
          container
          justifyContent=""
          alignItems="center"
          spacing={spacing}
          key={index}
        >
          {value.map((control, index) => {
            return (
              <Grid item {...itemResponsive} key={index}>
                {control && !control.hidden && (
                  <ViewInputControl form={form} {...control} />
                )}
              </Grid>
            );
          })}
        </Grid>
      ))}
    </div>
  );
};

export default GridFormControl;
