import { IPageRequest, IPagedResult, IResult } from "src/Shared/Interfaces";
import { IContactFilter } from "../Interfaces/IContactFilter";
import { Observable } from "rxjs";
import { ICompanyContact } from "../Interfaces/ICompanyContact";
import { CompanyContactApiUrl } from "../Tools/CompanyContactApiUrls";
import { IAddCompanyContact } from "../Interfaces";
import { BaseService } from "src/Shared/Services/Base.service";

class CompanyContactService extends BaseService {
  public getCompanyConatctList(
    request: IPageRequest<IContactFilter>
  ): Observable<IPagedResult<ICompanyContact>> {
    return this.http.post<IPagedResult<ICompanyContact>>(
      CompanyContactApiUrl.GetContacts,
      request
    );
  }

  public addCompanyContactList(request: IAddCompanyContact[]) {
    return this.http.post<IResult<string>>(
      CompanyContactApiUrl.Contacts,
      request
    );
  }

  public updateompanyContact(request: IAddCompanyContact) {
    return this.http.post<IResult<string>>(
      CompanyContactApiUrl.UpdateContacts,
      request
    );
  }

  public getPrimaryContactID(companyRowID: string) {
    return this.http.get<number | null>(
      CompanyContactApiUrl.GetPrimaryContactID(companyRowID)
    );
  }
  public getCompanyVendorType(companyRowID: string) {
    return this.http.get<number[] | null>(
      CompanyContactApiUrl.GetCompanyVendorType(companyRowID)
    );
  }
}

export default new CompanyContactService();
